<!-- Video Area -->
<section class="section call-action">
    <div class="container">
      <div class="row cta-content">
        <div class="col-12">
          <div class="section-title">
            <!-- <h3 class="wow zoomIn" data-wow-delay=".2s">Videos</h3> -->
            <h2 class="wow fadeInUp" data-wow-delay=".4s">Video Gallery</h2>
            <!-- <p class="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
              Ipsum available, but the majority have suffered alteration in some form.</p> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="single-table wow fadeInUp" data-wow-delay=".2s">
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/Thswjrd_v1o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/Thswjrd_v1o"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="single-table wow fadeInUp" data-wow-delay=".4s">
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/Pj0Fnl7KPdo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/Pj0Fnl7KPdo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Video Area -->