import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  imageObject: Array<object> = [
    {
      image: '../../../assets/images/screens/First.png',
      thumbImage: '../../../assets/images/screens/First.png',
    },
    {
      image: '../../../assets/images/screens/screen1.png',
      thumbImage: '../../../assets/images/screens/screen1.png',
    },
    {
      image: '../../../assets/images/screens/screen2.png',
      thumbImage: '../../../assets/images/screens/screen2.png',
    },
    {
      image: '../../../assets/images/screens/screen3.png',
      thumbImage: '../../../assets/images/screens/screen3.png',
    },
    {
      image: '../../../assets/images/screens/screen4.png',
      thumbImage: '../../../assets/images/screens/screen4.png',
    },
    {
      image: '../../../assets/images/screens/dashboard.png',
      thumbImage: '../../../assets/images/screens/dashboard.png',
    },
    {
      image: '../../../assets/images/screens/live bid status.png',
      thumbImage: '../../../assets/images/screens/live bid status.png',
    },
    {
      image: '../../../assets/images/screens/live bids png.png',
      thumbImage: '../../../assets/images/screens/live bids png.png',
    },
  ];

}
