import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
import { AchievementComponent } from './achievement/achievement.component';
import { OverviewComponent } from './overview/overview.component';
import { VideosComponent } from './videos/videos.component';
import { FAQsComponent } from './faqs/faqs.component';
import { ContactComponent } from './contact/contact.component';
import { NgImageSliderModule } from 'ng-image-slider';


@NgModule({
  declarations: [HomeComponent, FeaturesComponent, AchievementComponent, OverviewComponent, VideosComponent, FAQsComponent, ContactComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgImageSliderModule
  ]
})
export class PagesModule { }
