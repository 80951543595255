<!-- Start Features Area -->
<section class="features section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h3 class="wow zoomIn" data-wow-delay=".2s">Features</h3>
            <h2 class="wow fadeInUp" data-wow-delay=".4s">Your Experience Gets Better And Better Over Time.
            </h2>
            <!-- <p class="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
              Ipsum available, but the majority have suffered alteration in some form.</p> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Feature -->
          <div class="single-feature wow fadeInUp" data-wow-delay=".2s">
            <i class="lni lni-timer"></i>
            <h3>2-Min e-Registraction</h3>
            <p>Paperless and Frictionless.</p>
          </div>
          <!-- End Single Feature -->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Feature -->
          <div class="single-feature wow fadeInUp" data-wow-delay=".4s">
            <i class="lni lni-invest-monitor"></i>
            <h3>3-Click to invest</h3>
            <p>All the convenience you seek.</p>
          </div>
          <!-- End Single Feature -->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Feature -->
          <div class="single-feature wow fadeInUp" data-wow-delay=".6s">
            <i class="lni lni-travel"></i>
            <h3>Track Subscription</h3>
            <p style="text-align: justify;"> Track live subscription of every IPO.</p>
          </div>
          <!-- End Single Feature -->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Feature -->
          <div class="single-feature wow fadeInUp" data-wow-delay=".2s">
            <i class="lni lni-alarm"></i>
            <h3>Never miss an IPO</h3>
            <p style="text-align: justify;">Get an alert of an every IPO.</p>
          </div>
          <!-- End Single Feature -->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Feature -->
          <div class="single-feature wow fadeInUp" data-wow-delay=".4s">
            <i class="lni lni-direction"></i>
            <h3>Reinitiate Mandates</h3>
            <p style="text-align: justify;">Request mandates at will</p>
          </div>
          <!-- End Single Feature -->
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <!-- Start Single Feature -->
          <div class="single-feature wow fadeInUp" data-wow-delay=".6s">
            <i class="lni lni-graph"></i>
            <h3>Allotment analysis</h3>
            <p style="text-align: justify;">Check allotment in the app only.</p>
          </div>
          <!-- End Single Feature -->
        </div>
      </div>
    </div>
  </section>
  <!-- End Features Area -->