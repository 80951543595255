<!-- overview section -->
<section class="features section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h3 class="wow zoomIn" data-wow-delay=".2s">OverView</h3>
            <h2 class="wow fadeInUp" data-wow-delay=".4s">Overview of Application</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="single-table wow fadeInUp" data-wow-delay=".2s">
            <div>
              <ng-image-slider
              [imagePopup]="false"
              [infinite]="true"
              [autoSlide]="1"
              slideImage="1"
              animationSpeed = "1"
              [showArrow] = "false"
              [imageSize]="{width: 280, height: 375, space: 15}"
              [images]="imageObject" #nav></ng-image-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- overview section -->
  