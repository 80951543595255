<footer class="footer">
    <!-- Start Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <!-- Single Widget -->
            <div class="single-footer f-about">
              <div class="logo">
                <a href="index.html">
                  <img src="../assets/images//logo/logo.png" alt="#">
                </a>
              </div>
              <p>More then just investing.</p>
              <ul class="social">
                <li><a href="https://twitter.com/_fincase_in"><i class="lni lni-twitter-original"></i></a></li>
                <li><a href="https://www.instagram.com/fincase_in/"><i class="lni lni-instagram"></i></a></li>
                <li><a href="https://www.linkedin.com/company/fincase-in/"><i class="lni lni-linkedin-original"></i></a></li>
                <li><a href="https://t.me/FincaseIPO"><i class="lni lni-telegram-original"></i></a></li>
                <li><a href="https://chat.whatsapp.com/C1vb5oL2shy4SkREE2qP6M"><i class="lni lni-whatsapp"></i></a></li>
              </ul>
            </div>
            <!-- End Single Widget -->
          </div>
          <div class="col-lg-8 col-md-8 col-12">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-12">
                <!-- Single Widget -->
                <div class="single-footer f-link">
                  <h3>Useful links</h3>
                  <ul>
                    <li><a href="#home">Home</a></li>
                    <li><a href="https://ipo.fincase.in/ipolist">Easy IPO</a></li>
                    <li><a href="https://yashasvifinvest.com/mutualfund-login">Mutual Funds</a></li>
                    <li><a href="https://yashasvifinvest.com/ncd">NCD</a></li>
                    <li><a href="https://unlisted.yashasvifinvest.com/">Unlisted Shares</a></li>
                    <li><a href="#home">IEPF Solutions</a></li>
                  </ul>
                </div>
                <!-- End Single Widget -->
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <!-- Single Widget -->
                <div class="single-footer f-link">
                  <h3>Support</h3>
                  <ul>
                    <li><a href="https://www.nseindia.com/">NSE</a></li>
                    <li><a href="https://www.bseindia.com/">BSE</a></li>
                    <li><a href="https://www.sebi.gov.in/">SEBI</a></li>
                  </ul>
                </div>
                <!-- End Single Widget -->
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <!-- Single Widget -->
                <div class="single-footer f-link">
                  <h3>Legal</h3>
                  <ul>
                    <li><a href="https://ipo.fincase.in/terms_and_conditions">Terms & Conditions</a></li>
                    <li><a href="https://ipo.fincase.in/privacy_policy">Privacy Policy</a></li>
                    <li><a href="https://ipo.fincase.in/disclaimer">Disclaimer</a></li>
                  </ul>
                </div>
                <!-- End Single Widget -->
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <!-- Single Widget -->
                <div class="single-footer f-link">
                  <h3>Contact Us</h3>
                  <ul>
                    <li><a href="mailto:info@fincase.in"><i class="lni lni-envelope"></i>&nbsp;&nbsp;Info@fincase.in</a></li>
                    <li><a href="tel:+919974438500"><i class="lni lni-phone"></i>&nbsp;&nbsp;+91 99744 38500</a></li>
                  </ul>
                </div>
                <!-- End Single Widget -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ End Footer Top -->
  </footer>