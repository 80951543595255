<header class="header navbar-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="nav-inner">
            <!-- Start Navbar -->
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand-header" href="index.html">
                <img src="../../../assets/images/logo/logo.png" alt="Logo" style="height: 75px;">
              </a>
              <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="nav" class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <a href="#home" class="page-scroll active" aria-label="Toggle navigation">Home</a>
                  </li>
                  <li class="nav-item">
                    <a href="#features" class="page-scroll" aria-label="Toggle navigation">Features</a>
                  </li>
                  <li class="nav-item">
                    <a href="#overview" class="page-scroll" aria-label="Toggle navigation">Overview</a>
                  </li>
                  <li class="nav-item">
                    <a href="#videos" class="page-scroll" aria-label="Toggle navigation">Video Gallery</a>
                  </li>
                  <li class="nav-item">
                    <a href="#FAQs" class="page-scroll" aria-label="Toggle navigation">FAQs</a>
                  </li>
                  <li class="nav-item">
                    <a href="#contact" class="page-scroll" aria-label="Toggle navigation">Contact</a>
                  </li>
                  <!-- <li class="nav-item">
                    <a href="javascript:void(0)" aria-label="Toggle navigation">Team</a>
                  </li>
                  <li class="nav-item">
                    <a class="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse"
                      data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent" aria-expanded="false"
                      aria-label="Toggle navigation">Blog</a>
                    <ul class="sub-menu collapse" id="submenu-1-4">
                      <li class="nav-item"><a href="javascript:void(0)">Blog Grid Sidebar</a>
                      </li>
                      <li class="nav-item"><a href="javascript:void(0)">Blog Single</a></li>
                      <li class="nav-item"><a href="javascript:void(0)">Blog Single
                          Sibebar</a></li>
                    </ul>
                  </li> -->
                </ul>
              </div> <!-- navbar collapse -->
              <div class="button add-list-button">
                <a href="https://ipo.fincase.in/ipolist" class="btn">Apply Now</a>
              </div>
            </nav>
            <!-- End Navbar -->
          </div>
        </div>
      </div> <!-- row -->
    </div> <!-- container -->
  </header>
  <!-- End Header Area -->
  
  <!-- Home Area -->
  <section id="home" class="hero-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-12 col-12">
          <div class="hero-content">
            <h1 class="wow fadeInLeft" data-wow-delay=".4s">Welcome to Fincase!</h1>
            <p class="wow fadeInLeft" data-wow-delay=".6s">India's First 3 Step Investing Platform for IPO Investors and one stop solution which gives all the convenience you seek. 
              Download the application now!. It is powerful investing platform which makes IPO simple.</p>
            <div class="button wow fadeInLeft" data-wow-delay=".8s">
              <a href="https://apps.apple.com/app/fincase-ipo-info-ipo-apply/id6448801948" class="btn"><i
                  class="lni lni-apple"></i> App Store</a>
              <a href="https://play.google.com/store/search?q=fincase&c=apps" class="btn"><i
                  class="lni lni-play-store"></i> Google
                Play</a>
                <div class="mobileBtn">
                  <a href="https://ipo.fincase.in/ipolist" class="btn"><i 
                      class="lni lni-world"></i>Apply Now</a>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-12">
          <div class="hero-image wow fadeInRight" data-wow-delay=".4s">
            <img src="../../../assets/images/hero/screen.png" alt="#">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Home Area -->
  
  <!-- overview section -->
  <div id="features" style="scroll-margin-top: 100px">
    <app-features></app-features>
  </div>
  <!-- overview section -->
  
  <!-- Achievement Area -->
  <div id="achievement" style="scroll-margin-top: 100px">
    <app-achievement></app-achievement>
  </div>
  <!-- Achievement Area -->

  <!-- overview section -->
  <div id="overview" style="scroll-margin-top: 100px">
    <app-overview></app-overview>
  </div>
  <!-- overview section -->

  <!-- Video Area -->
  <div id="videos" style="scroll-margin-top: 100px">
    <app-videos></app-videos>
  </div>
  <!-- Video Area -->

  <!-- FAQ Action Area -->
  <div id="FAQs" style="scroll-margin-top: 100px">
    <app-faqs></app-faqs>
  </div>
  <!-- FAQ Action Area -->

  <!-- contact area -->
  <div id="contact" style="scroll-margin-top: 100px">
    <app-contact></app-contact>
  </div>
  <!-- contact area -->
