<!-- FAQ Action Area -->
<section class="pricing-table section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12  col-md-12 col-12">
          <div class="section-title">
            <h2 class="wow fadeInUp" data-wow-delay=".2s">FAQs</h2>
            <p class="wow fadeInUp" data-wow-delay=".4s">Answers to your all Questions!</p>
          </div>
            
          <div class="row">
              <div class="col-lg-6">
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h3 class="panel-title">Is it Free (Registration / Applying IPOs)?</h3>
                    <span class="pull-right clickable"><i class="glyphicon glyphicon-minus"></i></span>
                  </div>
                  <div class="panel-body">Yes, Getting Registered with Fincase & Applying IPOs is absolutely free.</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h3 class="panel-title">What are the details required for applying in IPO through Fincase?</h3>
                    <span class="pull-right clickable"><i class="glyphicon glyphicon-minus"></i></span>
                  </div>
                  <div class="panel-body">For applying in IPOs through Fincase, an Investor requires have PAN, Demat Account, Bank Account, UPI ID (for Applying through UPI ASBA).</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h3 class="panel-title">Can I apply through any Demat account?</h3>
                    <span class="pull-right clickable"><i class="glyphicon glyphicon-minus"></i></span>
                  </div>
                  <div class="panel-body">Yes, you don’t need to have Demat Account with us, you can Apply with Any Demat Account. As we say “Demat Kahin Bhi... Apply Idhar Hi...”</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h3 class="panel-title">Can I Apply for Family Members from my Login?</h3>
                    <span class="pull-right clickable"><i class="glyphicon glyphicon-minus"></i></span>
                  </div>
                  <div class="panel-body">Yes, you can apply for your family Members from your login, provided you have different UPI IDs for each family Members. You can’t use your UPI ID for Other Family Members as it becomes Third Party Transaction, which is not allowed.</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h3 class="panel-title">How to Check Allotment?</h3>
                    <span class="pull-right clickable"><i class="glyphicon glyphicon-minus"></i></span>
                  </div>
                  <div class="panel-body">You can find the Allotment Status Link from our application for all IPOs as and when its Announced.</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="panel panel-primary">
                  <div class="panel-heading">
                    <h3 class="panel-title">I haven’t received an IPO mandate instantly after applying. What should I do?</h3>
                    <span class="pull-right clickable"><i class="glyphicon glyphicon-minus"></i></span>
                  </div>
                  <div class="panel-body">SEBI mandates IPO applications between 10:00 AM to 5:00 PM, Monday to Friday. If you apply on Saturday or before an IPO opens, expect a delay until Monday or the IPO's opening, whichever is later. If you apply on the IPO's opening day and don't receive the UPI Mandate within 30 minutes, you can cancel and reapply with a fresh application.</div>
                </div>
              </div>
            </div>
            <p class="wow fadeInUp" data-wow-delay=".4s">If you've any feedback or questions related to the Fincase application then feel free to email us on 
            <a style="color: #E1872C;" href="mailto:info@fincase.in">info@fincase.in.</a></p>
  
        </div>
      </div>
    </div>
  </section>
  <!-- FAQ Action Area -->